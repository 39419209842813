
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Table from "@/components/Table.vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "index",
  components: {
    Table,
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("pages.organisation.list", [
        "pages.organisation.title",
      ]);
    },
  },
  data() {
    console.log();
    const store = useStore();
    const url = process.env.VUE_APP_API_URL + "/organisations/list"; // ✅
    const { n, t } = useI18n();

    return {
      ajax: url,
      order: [[0, "asc"]],
      columns: [
        {
          data: "id",
          title: "No",
          defaultContent: "-",
          className: "text-center",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          data: "name",
          defaultContent: "-",
          title: t("organisation.table.name"),
        },
        {
          data: "org_type",
          title: t("organisation.table.org_type"),
          defaultContent: "-",
          className: "text-center",
          render: function (data, type, row, meta) {
            return data ? t(`organisation.table.${data}`):'-';
          },
        },
        {
          data: "latitude",
          title: t("organisation.table.latitude"),
          defaultContent: "-",
          className: "text-center",

        },
        {
          data: "longitude",
          defaultContent: "-",
          title: t("organisation.table.longitude"),
          className: "text-center",
        },
        {
          data: "created_at",
          defaultContent: "-",
          title: t("organisation.table.created_at"),
          className: "text-center",
          render: function (data, type, row, meta) {
            return data ? moment(data).fromNow():'-';
          },
        },
        {
          data: "updated_at",
          defaultContent: "-",
          title: t("organisation.table.updated_at"),
          className: "text-center",
          render: function (data, type, row, meta) {
            return data ? moment(data).fromNow():'-';
          },
        },
        {
            data: "action",
            title: t("organisation.table.action"),
        },
      ],
    };
  },
  setup(props) {
    const { t } = useI18n();

    return { t };
  },
});
