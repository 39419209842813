import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.t("pages.organisation.list")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Table, {
            columns: _ctx.columns,
            order: _ctx.order,
            ajax: _ctx.ajax,
            width: "100%",
            class: "table table-responsive table bordered"
          }, null, 8, ["columns", "order", "ajax"])
        ])
      ])
    ])
  ]))
}